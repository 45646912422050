// Separating by group in case we want dynamic imports later

// Global components
import "./components/gg-app";
import "./components/top-nav";
import "./components/bottom-nav";
import "./components/notification-modal";

window.addEventListener('load', () => {
  // Home components
  if (document.querySelector('gg-app').hasAttribute('home')) {
    import("./components/gg-autocomplete");
    import("./components/news-slider");
  }

  // City hall components
  if (document.querySelector('city-council')) {
    import("./components/city-council");
    import("./components/council-member");
    import("./components/city-departments");
    import("./components/city-directors");
    import("./components/city-director");
  }

  // Services component
  if (document.querySelector('gg-services')) {
    import("./components/gg-services");
  }

  // MyGG components
  if (document.querySelector('ggapp-residents')) {
    import("./components/residents");
    import("./components/parks");
    import('./components/council-lookup');
    import('./components/trash-pickup');
    import('./components/street-sweeping');
  }

  // News components
  if (document.querySelector('gg-news-list')) {
    import("./components/gg-news-list");
    import("./components/news-list-item");
    import("./components/ggtv3-videos");
    import("./components/ggtv3-video");
  }

  // Notification history component
  if (document.querySelector('notification-history')) {
    import("./components/notification-history");
  }

  // Report Issue
  if (document.querySelector('report-issue')) {
    import("./components/report-issue");
    import("../public/ggg_map");
  }
});