import GGNotification from '../lib/gg-notification';

let template = document.createElement('template');

template.innerHTML = /*html*/`
  <style>
    :host {
      height: 100%;
      display: flex;
      flex-direction: column;
    }
    
    main {
      flex-grow: 1;
      overflow: auto;
    }

    .d-none {
      display: none;
    }
  </style>
  
  <header>
    <top-nav role="navigation" aria-label="Primary Navigation"></top-nav>
  </header>

  <main>
    <slot></slot>
  </main>
  <footer>
    <bottom-nav></bottom-nav>
  </footer>
`;

customElements.define('gg-app', class extends HTMLElement {
  constructor() {
    super();

    this.attachShadow({ mode: 'open' });
    this.shadowRoot.appendChild(template.content.cloneNode(true));
  }

  connectedCallback() {
    // Initializing notification will:
    // - Register service worker
    // - Request notification permission
    // - Dispatches notification events
    new GGNotification().initialize(this);
    this.addEventListener('push-notification', this._handlePushNotification);

    // If gg-app has a home attribute, then tell top-nav to render a special nav
    if (this.hasAttribute('home')) {
      this.shadowRoot.querySelector('top-nav').setAttribute('home', '');
    }
    
    if (this.querySelector('report-issue') !== null) {
      let f = this.shadowRoot.querySelector('footer');
      f.classList.add('d-none');
    }
  }
  
  _handlePushNotification(e) {
    // if one exists already, remove first
    if (this._notificationModal) this._notificationModal.remove();

    this._notificationModal = document.createElement('notification-modal');
    this._notificationModal.setAttribute('title', e.detail.notification.title);
    this._notificationModal.setAttribute('body', e.detail.notification.body);
    this._notificationModal.setAttribute('link', e.detail.link);

    this.shadowRoot.appendChild(this._notificationModal);
  }
});
